import { useEffect, useState } from "react"
import TypeProductCategory from "./TypeProductCategory";
import { Alert, Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from './style.module.css';
import { HttpNoData } from "../../../Core";
import { FaDownload, FaFileExport } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import { updatePropertyInList, updatePropertyInListFinance, updatePropertyInListNoFixed } from "./utils";
import CheckPrice from "./CheckPrice";
import DiscountProducts from "./DiscountProducts";
import { FaFileSignature } from "react-icons/fa6";

export default function Main({ jwt }) {

  const [isFile, setIsFile] = useState();
  const [currentCheckFile, setCurrentCheckFile] = useState('');
  const [fileOptions, setFileOptions] = useState();
  const [fileOptionsView, setFileOptionView] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [isViewingReceipt, setIsViewingReceipt] = useState(false);

  const [typeCategories, setTypeCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [priceOnWeight, setPriceOnWeight] = useState(1000);
  const [additionsInOrder, setAdditionsInOrder] = useState([]);

  const [weightOrder, setWeightOrder] = useState(0);
  const [nominalPrice, setNominalPrice] = useState(0);
  const [travelPrice, setTravelPrice] = useState(0);

  const [additionPercentPrice, setAdditionPercentPrice] = useState(0);
  const [additionValuePrice, setAdditionValuePrice] = useState(0);

  const [amounts, setAmounts] = useState([]);
  const [discountProducts, setDiscountsProducts] = useState([]);
  const [discountProductsUse, setDiscountsProductsUse] = useState([]);

  const [ndsList, setNdsList] = useState([]);
  const [currentNds, setCurrentNds] = useState(0);

  const [isAdditionOnSelfProduct, setIsAdditionOnSelfProduct] = useState(false);
  const [savePointSum, setSavePointSum] = useState(0);
  const [savePointNominal, setSavePointNominal] = useState(0);

  useEffect(() => {

    async function didMount() {
      const additionsResponse = await HttpNoData('/api/v1/calculateOrderCost/orders/additions', 'GET', jwt);
      const typesResponse = await HttpNoData('/api/v1/calculateOrderCost/typeProductCategories', 'GET', jwt);
      const categoriesResponse = await HttpNoData('/api/v1/calculateOrderCost/productCategories', 'GET', jwt);
      const productsResponse = await HttpNoData('/api/v1/calculateOrderCost/products', 'GET', jwt);
      const priceOnWeightResponse = await HttpNoData('/api/v1/calculateOrderCost/priceOnWeight', 'GET', jwt);
      const ndsListResponse = await HttpNoData(`/api/v1/calculateOrderCost/nds`, 'GET', jwt);

      setAdditionsInOrder(additionsResponse.data?.sort((a, b) => a.priority - b.priority) ?? []);
      setTypeCategories(typesResponse.data?.sort((a, b) => a.priority - b.priority) ?? []);
      setCategories(categoriesResponse.data?.sort((a, b) => a.priority - b.priority)  ?? [])
      setProducts(productsResponse.data?.sort((a, b) => a.priority - b.priority) ?? []);
      setPriceOnWeight(priceOnWeightResponse.data ?? 1000);
      setNdsList(ndsListResponse.data?.sort((a, b) => a.percent - b.percent) ?? []);
    }

    didMount();

  }, []);

  useEffect(() => {
    setDiscountsProductsUse(discountProducts.filter(f => f.isUse))
  }, [discountProducts]);

  useEffect(() => {

    if (!fileOptions)
      return;

    setTravelPrice(fileOptions.travelPrice);
    setNominalPrice(fileOptions.nominalPrice);
    setWeightOrder(fileOptions.weightOrder);

    setAdditionPercentPrice(fileOptions.additionPercentPrice);
    setAdditionValuePrice(fileOptions.additionValuePrice);
    setDiscountsProducts(fileOptions.discountProducts);
    setCurrentNds(fileOptions.currentNds);

    for (let i = 0; i < additionsInOrder.length; i++) {
      const element = additionsInOrder[i];

      const find = fileOptions.additions.find(f => f.id == element.id);

      if (!find)
        continue;

      additionsInOrder[i] = find;
    }

    setAdditionsInOrder([...additionsInOrder, ...fileOptions.additions.filter(f => additionsInOrder.findIndex(find => find.id == f.id) == -1)].sort((a, b) => b.priority - a.priority))

  }, [fileOptions]);

  const updateAutoWeight = (weight, price) => {

    if (!additionsInOrder.find(f => f.id == 1)?.isUse)
      return;

    if (weight == 0) {
      setTravelPrice(0);
      return;
    }

    const chunk = weight / 3;

    if (chunk % 1 == 0)
      setTravelPrice(chunk * price);
    else {
      const chunkTemp = chunk % 1;
      setTravelPrice((chunk - chunkTemp + 1) * price);
    }
  }

  useEffect(() => {

    updateAutoWeight(weightOrder, priceOnWeight);

  }, [weightOrder]);

  let sumPrice = nominalPrice;

  if (!isAdditionOnSelfProduct)
    sumPrice += travelPrice + additionValuePrice;

  sumPrice += sumPrice * (additionPercentPrice / 100);

  sumPrice += sumPrice * (currentNds / 100);

  sumPrice = +sumPrice;

  let additionCoefficient = 0;
  if (savePointNominal != 0 && additionValuePrice + travelPrice != 0)
    additionCoefficient = (additionValuePrice + travelPrice) / savePointNominal;

  return <div className={styles.main}>

    <div className={styles.bodyTypes}>
      {
        typeCategories && typeCategories.length > 0 ?
          typeCategories.map(o => <TypeProductCategory
            fileOptions={fileOptions}
            discountProducts={discountProductsUse}
            additionCoefficient={isAdditionOnSelfProduct ? additionCoefficient : 0}
            key={o.id}
            onSum={(c, s) => setAmounts(updatePropertyInListNoFixed(amounts, 'id', c.id, 'sum', s, (s) => setNominalPrice(s)))}
            onWeight={(c, s) => setAmounts(updatePropertyInList(amounts, 'id', c.id, 'weight', s,
              (s) => setWeightOrder(+((s / 1000).toFixed(3)))))
            }
            type={o}
            categories={categories}
            onUpdate={(update) => {
              const index = updates.findIndex(f => f.key == update.key);

              if (index != -1)
                updates[index] = update;
              else updates.push(update);
            }}
            jwt={jwt}
            products={products} />)
          : <Alert variant="warning">Пусто</Alert>
      }
    </div>

    <Row className={styles.bodyAdditionInOrder}>
      <Col sm={3}>
        <Row>
          <Button onClick={() => setIsAdditionOnSelfProduct(!isAdditionOnSelfProduct) || setSavePointSum(sumPrice) || setSavePointNominal(nominalPrice)}>
            Пересчет допиков в стоимость товара: {isAdditionOnSelfProduct ? 'вкл' : 'выкл'}
          </Button>
        </Row>
        <h3>Допики. к заказу</h3>
        {
          additionsInOrder.map(o => <InputGroup key={o.id} className="mb-3">
            <OverlayTrigger overlay={<Tooltip id={`addition-in-order-tooltip-${o.id}`}>{o.description} - [{o.coefficientValue}]</Tooltip>}>
              <InputGroup.Text>{o.name}</InputGroup.Text>
            </OverlayTrigger>
            <Form.Control
              disabled={o.id != 1 ? !o.isUse : false}
              id={`addition-in-order-${o.id}`}
              value={o.id == 1 ? travelPrice : o.value}
              onChange={(e) => {

                if (o.id == 1) {
                  setTravelPrice(+e.target.value);
                  return;
                }

                const index = additionsInOrder.findIndex(f => f.id == o.id);
                additionsInOrder[index].value = +e.target.value;
                setAdditionsInOrder([...additionsInOrder]);

                if (additionsInOrder[index].math == 1 || additionsInOrder[index].math == 3)
                  setAdditionValuePrice(additionsInOrder.filter(f => (f.math == 1 || f.math == 3) && f.isUse).reduce((a, b) => a + (b.math == 1 ? b.value : b.value * -1) * b.coefficientValue, 0))
                else setAdditionPercentPrice(additionsInOrder.filter(f => (f.math == 2 || f.math == 4) && f.isUse).reduce((a, b) => a + (b.math == 2 ? b.value : b.value * -1) * b.coefficientValue, 0))

              }}
            />
            {
              o.id == 1 ?
                <Button variant="outline-success" onClick={() => updateAutoWeight(weightOrder, priceOnWeight)}>
                  <GrPowerReset />
                </Button> : ''
            }
            <InputGroup.Text style={{ borderColor: o.math == 3 || o.math == 4 ? 'green' : 'red' }}>{o.id == 5 ? 'шт.' : o.math == 1 || o.math == 3 ? '₽.' : '%'}</InputGroup.Text>
            <InputGroup.Checkbox checked={o.isUse} onChange={e => {

              if (o.id == 1)
                setTravelPrice(e.target.checked ? o.value : 0);

              const index = additionsInOrder.findIndex(f => f.id == o.id);
              additionsInOrder[index].isUse = e.target.checked;
              setAdditionsInOrder([...additionsInOrder]);

              if (additionsInOrder[index].math == 1 || additionsInOrder[index].math == 3)
                setAdditionValuePrice(additionsInOrder.filter(f => (f.math == 1 || f.math == 3) && f.isUse).reduce((a, b) => a + (b.math == 1 ? b.value : b.value * -1) * b.coefficientValue, 0))
              else setAdditionPercentPrice(additionsInOrder.filter(f => (f.math == 2 || f.math == 4) && f.isUse).reduce((a, b) => a + (b.math == 2 ? b.value : b.value * -1) * b.coefficientValue, 0))
            }} />
          </InputGroup>)
        }
        <InputGroup className="mb-3">
          <InputGroup.Text>НДС %</InputGroup.Text>
          <Form.Select
            value={currentNds}
            onChange={e => setCurrentNds(e.target.value)}
          >
            {
              ndsList.findIndex(f => f.percent == 0) != -1 ? '' : <option value={0}>0%</option>
            }
            {
              ndsList.map(map => <option key={map.id} value={map.percent}>{map.percent}%</option>)
            }
          </Form.Select>

        </InputGroup>
        <DiscountProducts discounts={discountProducts} onUpdate={(e) => setDiscountsProducts(e)}
          products={updates.filter(function (item, pos, self) {
            return self.findIndex(f => f.id == item.id) == pos;
          })} />
      </Col>
      <Col sm={9}>
        <h3>Итог</h3>
        <div>
          <div className={styles.productPriceItem}>
            <span className={styles.price_name}>Вес заказа </span>
            <span className={styles.price_separator} />
            <span className={styles.price_value}>{weightOrder}кг</span>
          </div>
          <div className={styles.productPriceItem}>
            <span className={styles.price_name}>Стоимость заказа </span>
            <span className={styles.price_separator} />
            <span className={styles.price_value}>{nominalPrice}₽</span>
          </div>
          <div className={styles.productPriceItem}>
            <span className={styles.price_name}>Доп. к заказу </span>
            <span className={styles.price_separator} />
            <span className={styles.price_value}>{isAdditionOnSelfProduct ? 0 : +(additionValuePrice + travelPrice).toFixed(0)}₽</span>
          </div>
          <div className={styles.productPriceItemSum}>
            <span className={styles.price_name}>Итого </span>
            <span className={styles.price_separator} />
            <span className={styles.price_value}>{sumPrice.toFixed(2)}₽</span>
          </div>
          {
            isAdditionOnSelfProduct ?
              <div className={styles.productPriceItemSum}>
                <span className={styles.price_name}>Разница </span>
                <span className={styles.price_separator} />
                <span className={styles.price_value}>{savePointSum}₽ - {sumPrice}₽ = {+(savePointSum - sumPrice).toFixedNoRounding(2)}₽</span>
              </div> : ''
          }
        </div>
        <div className={styles.buttonsAction}>
          <Button variant="success" className={styles.buttonSave} onClick={() => {
            const myData = {
              date: new Date().toJSON(),
              version: 'V1',
              currentNds: currentNds,
              products: updates,
              sumPrice: sumPrice,
              travelPrice: travelPrice,
              nominalPrice: nominalPrice,
              weightOrder: weightOrder,
              additions: additionsInOrder.filter(f => f.isUse && f.id != 1),
              additionPercentPrice: additionPercentPrice,
              additionValuePrice: additionValuePrice,
              discountProducts: discountProducts
            };
            // is an object and I wrote it to file as
            // json

            // create file in browser
            const fileName = "check-price";
            const json = JSON.stringify(myData, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const href = URL.createObjectURL(blob);

            // create "a" HTLM element with href to file
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          }}>
            <FaDownload style={{ marginRight: 6 }} />
            <span>Сохранить</span>
          </Button>
          <Button variant="outline-success" className={styles.buttonSave} onClick={() => {
            const myData = {
              date: new Date().toJSON(),
              version: 'V1',
              currentNds: currentNds,
              products: updates,
              sumPrice: sumPrice,
              travelPrice: travelPrice,
              nominalPrice: nominalPrice,
              weightOrder: weightOrder,
              additions: additionsInOrder.filter(f => f.isUse && f.id != 1),
              additionPercentPrice: additionPercentPrice,
              additionValuePrice: additionValuePrice,
              discountProducts: discountProducts
            };
            const json = JSON.stringify(myData, null, 2);
            setCurrentCheckFile(json);
            setIsFile(true);

          }}>
            <FaFileExport style={{ marginRight: 6 }} />
            <span>Экспорт</span>
          </Button>
          <Button variant="outline-success" className={styles.buttonImport} onClick={() => setFileOptionView(!fileOptionsView)}>
            <FaFileSignature style={{ marginRight: 6 }} />
            <span>Подгрузить</span>
          </Button>

        </div>
      </Col>
      {
        fileOptionsView ?
          <Form.Control
            type={'file'}
            accept=".json"
            className="m-3"
            onChange={(e) => {
              const reader = new FileReader();
              reader.onload = (e) => setFileOptions(JSON.parse(e.target.result));
              reader.readAsText(e.target.files[0]);
            }}
          /> : ''
      }
    </Row>
    {
      isFile ?
        <CheckPrice
          currentReceipt={currentCheckFile}
          onHide={() => { setIsFile(false); setCurrentCheckFile(''); }}
        />
        : ''
    }
  </div>
}