import { useEffect, useMemo, useState } from "react"
import { Form, InputGroup, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import styles from './style.module.css'

export default function CheckPrice({ onHide, currentReceipt }) {

    const [file, setFile] = useState('');
    const [check, setCheck] = useState();

    useEffect(() => {
        if (currentReceipt != '') setCheck(JSON.parse(currentReceipt));
        if (!file)
            return;

        setCheck(JSON.parse(file));

    }, [file, currentReceipt]);

    const content = useMemo(() => {

        if (!check)
            return '';

        return <div>
            <InputGroup className="mb-3">
                <InputGroup.Text>Дата</InputGroup.Text>
                <Form.Control
                    value={new Date(check.date).toLocaleString('ru-RU', {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })}
                    readOnly
                />
            </InputGroup>
            <div>
                {
                    check.products.map(p => <div key={p.id}>
                        <div className={styles.tabItemInCheck}>
                            <h4>{p.name}</h4>
                            <div className={styles.productPriceItem}>
                                <span className={styles.price_name}>Номинал </span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.nominalPrice}₽</span>
                            </div>
                            {
                                p.additions && p.additions.length > 0 ?
                                    <div className={styles.borderTop}>{
                                        p.additions.map(ad => <div key={ad.id} className={styles.productPriceItem}>
                                            <span className={styles.price_name}>{ad.name} </span>
                                            <span className={styles.price_separator} />
                                            <span className={styles.price_value}>{ad.price}₽</span>
                                        </div>)
                                    }</div>
                                    : ''
                            }
                            {
                                p.discountsPercent && p.discountsPercent.length > 0 ?
                                    <div className={styles.borderTop}>{
                                        p.discountsPercent.map(ad => <div key={ad.id} className={styles.productPriceItem}>
                                            <span className={styles.price_name}>Скидка в % </span>
                                            <span className={styles.price_separator} />
                                            <span className={styles.price_value}>{ad.percent}%</span>
                                        </div>)
                                    }</div>
                                    : ''
                            }
                            {
                                p.discountsValue && p.discountsValue.length > 0 ?
                                    <div className={styles.borderTop}>{
                                        p.discountsValue.map(ad => <div key={ad.id} className={styles.productPriceItem}>
                                            <span className={styles.price_name}>Скидка в ₽ </span>
                                            <span className={styles.price_separator} />
                                            <span className={styles.price_value}>{ad.value}₽</span>
                                        </div>)
                                    } </div>
                                    : ''
                            }
                            <div className={styles.productPriceItem}>
                                <span className={styles.price_name}>Характеристики </span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.sizeUser}мм х {p.thicknessUser}мм</span>
                            </div>
                            <div className={styles.productPriceItem}>
                                <span className={styles.price_name}>Кол-во </span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.selfCount}шт.</span>
                            </div>
                            <div className={styles.productPriceItem}>
                                <span className={styles.price_name}>Цена шт. </span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.priceSelf}₽</span>
                            </div>
                            <div className={styles.productPriceItemSum}>
                                <span className={styles.price_name}>Итого </span>
                                <span className={styles.price_separator} />
                                <span className={styles.price_value}>{p.priceSumCount}₽</span>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <div className={styles.tabItemInCheck}>
                <h4>Допики. к заказу</h4>
                {
                    check.additions && check.additions.length > 0 ?
                        check.additions.map(ad => <div key={ad.id} className={styles.productPriceItem}>
                            <span className={styles.price_name}>{ad.name} </span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{(ad.math == 1 || ad.math == 2 ? ad.value : ad.value * -1) * ad.coefficientValue}{ad.math == 1 || ad.math == 3 ? '₽' : '%'}</span>
                        </div>) : <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Пусто </span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>0₽</span>
                        </div>
                }

                <div className={styles.productPriceItemSum}>
                    <span className={styles.price_name}>Допики. ₽ </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{check.additionValuePrice}₽</span>
                </div>
                <div className={styles.productPriceItem}>
                    <span className={styles.price_name}>Допики. % </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{check.additionPercentPrice}%</span>
                </div>
            </div>

            <div className={styles.tabItemInCheck}>
                <h4>Допики. к заказу (кастом)</h4>
                {
                    check.discountProducts && check.discountProducts.length > 0 ?
                        check.discountProducts.map(ad => <div key={ad.id} className={styles.productPriceItem}>
                            <span className={styles.price_name}>
                                <OverlayTrigger overlay={<Tooltip id={`addition-in-order-custom-check-tooltip-${ad.id}`}>{ad.products.map(map => map.name).join(', ')}</Tooltip>}>
                                    <span>{ad.name} </span>
                                </OverlayTrigger>
                            </span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>{ad.value}{ad.math == 1 ? '₽' : '%'}</span>
                        </div>) : <div className={styles.productPriceItem}>
                            <span className={styles.price_name}>Пусто </span>
                            <span className={styles.price_separator} />
                            <span className={styles.price_value}>0₽</span>
                        </div>
                }
            </div>
            <div className={styles.tabItemInCheck}>
                <h4>Итого</h4>
                <div className={styles.productPriceItem}>
                    <span className={styles.price_name}>Вес заказа </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{check.weightOrder}кг</span>
                </div>
                <div className={styles.productPriceItem}>
                    <span className={styles.price_name}>Номинал заказа </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{check.nominalPrice}₽</span>
                </div>
                <div className={styles.productPriceItemSum}>
                    <span className={styles.price_name}>Доставка </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{check.travelPrice}₽</span>
                </div>
                <div className={styles.productPriceItem}>
                    <span className={styles.price_name}>С доставкой </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{(check.travelPrice + check.nominalPrice).toFixed(0)}₽</span>
                </div>
                <div className={styles.productPriceItemSum}>
                    <span className={styles.price_name}>Итого </span>
                    <span className={styles.price_separator} />
                    <span className={styles.price_value}>{check.sumPrice}₽</span>
                </div>
            </div>
        </div>

    }, [check]);

    return <Modal show={true} onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>Просмотр чека {check?.version}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control
                type={'file'}
                accept=".json"
                className="mb-3"
                onChange={(e) => {
                    const reader = new FileReader();
                    reader.onload = (e) => setFile(e.target.result);
                    reader.readAsText(e.target.files[0]);
                }}
            />

            {
                content
            }
        </Modal.Body>
    </Modal>
}
