import { Alert, Button, Form, Modal, Stack } from "react-bootstrap";
import { useState } from "react";
import { HttpData } from "../../../Core";

export default function EditFormula({ item, show, onHide, extraWorks, extraWorksCategories, productions, productionsCategories, productionsInWarehouse,productionsInWarehouseCategories, onUpdated, jwt }) {

    const [copyEdit, setIsCopyEdit] = useState({ ...item });

    const [isViewExtraWorks, setIsViewExtraWorks] = useState(false);
    const [isViewProductions, setIsViewProductions] = useState(false);
    const [isViewProductWarehouse, setIsViewProductWarehouse] = useState(false);

    const submit = async (e) => {
        e.preventDefault();

        const responseCreated = await HttpData(`/api/v1/formulas`, 'PUT', jwt, copyEdit);

        if (responseCreated.statusSuccessful)
            onUpdated();
        else alert('Что-то пошло не так')
    }

    if (isViewExtraWorks)
        return <Modal show={isViewExtraWorks} onHide={() => setIsViewExtraWorks(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Работы</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                     extraWorksCategories.map(c => extraWorks.length > 0 ?
                        <div>
                            <div key={c.id}><b>{c.name}</b></div>
                            {
                                extraWorks.map(w => w.extraWorkCategoryId == c.id && <div key={w.id}>{w.name} - {w.id}</div>)
                            }
                        </div>
                        : <Alert variant="warning">{c.name} - Пусто</Alert>)
                }
            </Modal.Body>
        </Modal>

    if (isViewProductions)
        return <Modal show={isViewProductions} onHide={() => setIsViewProductions(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Продукция</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    productionsCategories.map(c => productions.length > 0 ?
                        <div>
                            <div key={c.id}><b>{c.name}</b></div>
                            {
                                productions.map(w => w.categoryId == c.id && <div key={w.id}>{w.name} - {w.id}</div>)
                            }
                        </div>
                        : <Alert variant="warning">{c.name} - Пусто</Alert>)
                }
            </Modal.Body>
        </Modal>
    if (isViewProductWarehouse)
        return <Modal show={isViewProductWarehouse} onHide={() => setIsViewProductWarehouse(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Готовая продукция</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    productionsInWarehouseCategories.map(c => productionsInWarehouse.length > 0 ?
                        <div>
                            <div key={c.id}><b>{c.name}</b></div>
                            {
                                productionsInWarehouse.map(w => w.categoryId == c.id && <div key={w.id}>{w.name} - {w.id}</div>)
                            }
                        </div>
                        : <Alert variant="warning">{c.name} - Пусто</Alert>)
                }
            </Modal.Body>
        </Modal>

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={submit}>
            <Modal.Header closeButton>
                <Modal.Title>{item.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Название</Form.Label>
                    <Form.Control
                        value={copyEdit.name}
                        onChange={(e) => setIsCopyEdit({ ...copyEdit, name: e.target.value })}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Расшифровка</Form.Label>
                    <Form.Control
                        value={copyEdit.formula}
                        as="textarea"
                        onChange={(e) => setIsCopyEdit({ ...copyEdit, formula: e.target.value })}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Формула</Form.Label>
                    <Form.Control
                        value={copyEdit.formulaMath}
                        as="textarea"
                        onChange={(e) => setIsCopyEdit({ ...copyEdit, formulaMath: e.target.value })}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Ключи</Form.Label>
                    <Stack gap={1}>
                        <Button onClick={() => setIsViewExtraWorks(true)}>Работы</Button>
                        <Button onClick={() => setIsViewProductions(true)}>Продукция</Button>
                        <Button onClick={() => setIsViewProductWarehouse(true)}>Готовая продукция</Button>
                    </Stack>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Инструкция</Form.Label>
                    <Alert variant="info">- работа сотрудника w12<br />- продукция менеджера m22<br />- готовая продукция s5<br />Обратите внимание что используются буквы m , s , w</Alert>
                    <Alert variant="danger">Неправильная формула будет всегда равна 0</Alert>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Пример</Form.Label>
                    <Alert variant="secondary">{'( 1 - {m2} + {w22} ) / {s1}'}</Alert>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>отмена</Button>
                <Button variant="success" type="submit">сохранить</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}