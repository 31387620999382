import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
	deleteNotification,
	getNotifications,
	getNotificationTypes,
	getWorkCategories,
	getWorkerCategories,
	getWorkers,
} from '../../../apiMethod/notifications'
import Notification from './Notification/Notification'
import NotificationCreateModel from './NotificationCreateModel/NotificationCreateModel'
import styles from './Notifications.module.css'
const Notifications = ({ jwt }) => {
	const [notificationsList, SetNotificationsList] = useState([])
	const [notificationTypes, setNotificationTypes] = useState([])
	const [workCategories, setWorkCategories] = useState([])
	const [workers, setWorkers] = useState([])
	const [workerCategories, setWorkerCategories] = useState([])
	const [isCreateModalShow, setIsCreateModalShow] = useState(false)

	useEffect(() => {
		async function didMount() {
			SetNotificationsList(await getNotifications(jwt))
			setNotificationTypes(await getNotificationTypes(jwt))
			setWorkCategories(await getWorkCategories(jwt))
			setWorkers(await getWorkers(jwt))
			setWorkerCategories(await getWorkerCategories(jwt))
		}
		didMount()
	}, [])

	const updateNotificationsList = notificationData => {
		SetNotificationsList(prev =>
			Array.isArray(prev) ? [...prev, notificationData] : [notificationData]
		)
	}

	const handleDelete = id => {
		SetNotificationsList(notificationsList.filter(obj => obj.id !== id))
		deleteNotification(jwt, id)
	}

	const handleEdit = data => {
		SetNotificationsList(
			notificationsList.map(notification => {
				if (notification.id === data.id) {
					return data
				}
				return notification
			})
		)
	}

	return (
		<>
			{isCreateModalShow ? (
				<NotificationCreateModel
					workerCategories={workerCategories}
					workers={workers}
					notificationTypes={notificationTypes}
					setData={updateNotificationsList}
					isShow={isCreateModalShow}
					onHide={() => setIsCreateModalShow(false)}
					jwt={jwt}
				/>
			) : (
				''
			)}
			<div className={styles.notifications_layout}>
				<Button
					className={styles.button}
					onClick={() => setIsCreateModalShow(!isCreateModalShow)}
				>
					Создать уведомление
				</Button>
				<h1>Список активных</h1>
				{notificationsList && notificationsList.length > 0 ? (
					notificationsList.map(obj => {
						return (
							<Notification
								workCategories={workCategories}
								workerCategories={workerCategories}
								workers={workers}
								jwt={jwt}
								handleEdit={handleEdit}
								key={obj.id}
								notificationTypes={notificationTypes}
								data={obj}
								handleDelete={handleDelete}
							/>
						)
					})
				) : (
					<h2>Упс... уведомлений нет</h2>
				)}
			</div>
		</>
	)
}

export default Notifications
