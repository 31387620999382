import React, { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { postNotification } from '../../../../apiMethod/notifications.js'
import EditCategoryView from '../EditCategoryView/EditCategoryView.jsx'
import EditWorkersView from '../EditWorkersView/EditWorkersView.jsx'
import '../Utils/utils.js'
import { notificationPriorityList } from '../Utils/utils.js'
const NotificationCreateModel = ({
	workerCategories,
	workers,
	workCategories,
	setData,
	jwt,
	isShow,
	onHide,
	notificationTypes,
}) => {
	const [show, setShow] = useState(isShow)
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
	const [notificationData, setNotificationData] = useState({
		type: notificationTypes && notificationTypes.length > 0 ? notificationTypes[0].key : '0',
		message: '',
		priority: "0",
		startDate: "",
		endDate: "",
		workersIdes: [],
		workerCategoriesIdes: [],
	})
	const [initialData, setInitialData] = useState({
		type: notificationTypes && notificationTypes.length > 0 ? notificationTypes[0].key : '0',
		message: '',
		priority: "0",
		startDate: "",
		endDate: "",
		workersIdes: [],
		workerCategoriesIdes: [],
	})

	const [showCategoryModal, setShowCategoryModal] = useState(false)
	const [showWorkersModal, setShowWorkersModal] = useState(false)
	const [validated, setValidated] = useState(false);

	const handleShow = () => setShow(true)
	const handleClose = () => {
		if (hasUnsavedChanges) {
			if (window.confirm('У вас есть несохраненные изменения. Вы действительно хотите закрыть?')) {
				setShow(false);
				setHasUnsavedChanges(false);
				onHide();
			}
		} else {
			setShow(false);
			onHide();
		}
	}
	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (hasUnsavedChanges) {
				e.preventDefault();
				e.returnValue = 'У вас есть несохраненные изменения. Вы уверены, что хотите уйти?';
				return e.returnValue;
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [hasUnsavedChanges]);

	useEffect(() => {
		const handlePopState = (e) => {
			if (hasUnsavedChanges && !window.confirm('У вас есть несохраненные изменения. Вы уверены, что хотите уйти?')) {
				window.history.pushState(null, '', window.location.pathname);
			}
		};

		window.history.pushState(null, '', window.location.pathname);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [hasUnsavedChanges]);

	useEffect(() => {
		if (JSON.stringify(notificationData) !== JSON.stringify(initialData))
			setHasUnsavedChanges(true);
		else
			setHasUnsavedChanges(false);
	}, [notificationData.message, notificationData.priority, notificationData.workersIdes, notificationData.workerCategoriesIdes, notificationData.type, notificationData.startDate, notificationData.endDate])

	const handleSubmit = event => {
		const form = event.currentTarget
		event.preventDefault()
		if (form.checkValidity() === false) {
			event.stopPropagation()
		}

		if (form.checkValidity()) {

			const requestData = { ...notificationData };
			requestData.startDate += ':00';
			requestData.endDate += ':00';

			postNotification(jwt, requestData)
			setData(notificationData)
			setHasUnsavedChanges(false);
			setShow(false);
			onHide();
		}

		setValidated(true)
	}

	return (
		<>
			{showCategoryModal ? (
				<EditCategoryView
					closeButton
					workCategories={workCategories}
					jwt={jwt}
					data={notificationData}
					show={showCategoryModal}
					setShow={setShowCategoryModal}
					setData={setNotificationData}
				/>
			) : (
				''
			)}
			{showWorkersModal ? (
				<EditWorkersView
					workerCategories={workerCategories}
					workers={workers}
					jwt={jwt}
					data={notificationData}
					show={showWorkersModal}
					setShow={setShowWorkersModal}
					setData={setNotificationData}
				/>
			) : (
				''
			)}
			<Modal show={show} onHide={handleClose}>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Создать уведомление</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className='mb-3'>
							<Form.Label>Тип</Form.Label>
							<Form.Select
								value={notificationData?.type}
								onChange={e =>
									setNotificationData({
										...notificationData,
										type: e.target.value,
									})
								}
							>
								{notificationTypes && notificationTypes.length > 0 ? (
									notificationTypes.map((o, id) => (
										<option value={o.key} key={o.id}>
											{o.name}
										</option>
									))
								) : (
									<option>Упс... Типы уведомлений не подгрузились</option>
								)}
							</Form.Select>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Приоритет</Form.Label>
							<Form.Select
								isValid={notificationData?.priority}
								value={notificationData?.priority}
								onChange={e =>
									setNotificationData({
										...notificationData,
										priority: e.target.value,
									})
								}
							>
								{notificationPriorityList.map((o, id) => (
									<option value={o.id} key={id}>
										{o.name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
						{/* <Form.Group className='mb-3'>
							<Form.Label>Активно</Form.Label>
							<Form.Select
								isValid={notificationData?.isAction}
								value={notificationData?.isAction}
								onChange={e =>
									setNotificationData({
										...notificationData,
										isAction: e.target.value,
									})
								}
							>
								<option value='false'>Нет</option>
								<option value='true'>Да</option>
							</Form.Select>
						</Form.Group> */}
						<Form.Group className='mb-3'>
							<Form.Label>Дата начала</Form.Label>
							<Form.Control
								isValid={notificationData?.startDate}
								required
								type='datetime-local'
								value={notificationData?.startDate}
								onChange={e => setNotificationData({ ...notificationData, startDate: e.target.value })}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Дата конца</Form.Label>
							<Form.Control
								isValid={notificationData?.endDate}
								required
								type='datetime-local'
								value={notificationData?.endDate}
								onChange={e => setNotificationData({ ...notificationData, endDate: e.target.value })}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Работники</Form.Label>
							<br></br>
							<Button variant='info' onClick={() => setShowWorkersModal(true)}>
								Редактировать работников
							</Button>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Категории работ</Form.Label>
							<br></br>
							<Button variant='info' onClick={() => setShowCategoryModal(true)}>
								Редактировать категории работ
							</Button>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Сообщение</Form.Label>
							<Form.Control
								required
								as='textarea'
								rows={3}
								value={notificationData?.message}
								onChange={e =>
									setNotificationData({
										...notificationData,
										message: e.target.value,
									})
								}
							></Form.Control>
							<Form.Control.Feedback type={'invalid'}>
								Обязательно
							</Form.Control.Feedback>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Закрыть
						</Button>
						<Button variant='primary' type='submit'>
							Сохранить
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

export default NotificationCreateModel
