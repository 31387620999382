import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
const EditWorkersView = ({ show, setShow, data, setData, workers, workerCategories }) => {
	const [allChecked, setAllChecked] = useState(false)

	const [notificationData, setNotificationData] = useState(data)

	useEffect(() => {
		setNotificationData(data)
	}, [data])

	const sortedCategories = workerCategories == undefined ? [] : [...workerCategories]?.sort((a, b) => {
		if (a.name === "Исключены") return 1  // "Исключены" всегда в конце
		if (b.name === "Исключены") return -1
		return a.name.localeCompare(b.name);
	})

	return (
		<Modal show={show}>
			<Modal.Header closeButton>
				<b>Выберите работников</b>
			</Modal.Header>
			<Modal.Body>
				<Form>
					{workers && workers.length > 0 ? (
						<>
							<Form.Check
								label='Все'
								name='all'
								onChange={e => {
									const { checked } = e.target
									setAllChecked(checked)
									setNotificationData({
										...notificationData,
										workersIdes: checked ? [] : (notificationData.workersIdes || [])
									})
								}}
							/>

							{sortedCategories?.map(category => {
								const categoryWorkers = workers
									.filter(worker => worker.categoryId === category.id)
									.sort((a, b) => a.secondName.localeCompare(b.secondName))


								if (categoryWorkers.length === 0) return null

								return (
									<div key={category.id || 'uncategorized'} style={{ marginTop: '15px' }}>
										<h6>{category.name}</h6>
										{categoryWorkers.map(worker => (
											<Form.Check
												key={worker.id}
												label={`${worker.secondName} ${worker.firstName} ${worker.thirdName}`}
												name={worker.id}
												checked={
													(notificationData.workersIdes &&
														notificationData.workersIdes.includes(worker.id)) ||
													(allChecked && !notificationData.workersIdes?.length)
												}
												onChange={e => {
													const { checked } = e.target
													setNotificationData({
														...notificationData,
														workersIdes: checked
															? [...(notificationData.workersIdes || []), worker.id]
															: (notificationData.workersIdes || []).filter(id => id !== worker.id)
													})
													setAllChecked(false)
												}}
											/>
										))}
									</div>
								)
							})}
						</>
					) : (
						<p>Упс... работники не загрузились</p>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => setShow(false)}>
					Закрыть
				</Button>
				<Button
					variant='primary'
					type='submit'
					onClick={() => {
						setShow(false)
						setData(notificationData)
					}}
				>
					Сохранить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EditWorkersView
