import React, { useEffect, useState } from 'react'
import {
	Accordion,
	Badge,
	Button,
	Col,
	Container,
	Row,
	Stack,
} from 'react-bootstrap'
import { putNotification } from '../../../../apiMethod/notifications.js'
import EditDeleteView from '../NotificationDeleteView/NotificationDeleteView'
import NotificationEditView from '../NotificationEditView/NotificationEditView'
import styles from './Notification.module.css'
const Notification = ({
	data,
	handleDelete,
	handleEdit,
	jwt,
	workers,
	workerCategories,
	workCategories,
	notificationTypes
}) => {
	const [notificationData, setNotificationData] = useState(data)
	const [id] = useState(notificationData.id)
	const [isShouldUpdate, setIsShouldUpdate] = useState(false)
	const [show, setShow] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	useEffect(() => {
		if (isShouldUpdate) {
			putNotification(jwt, id, notificationData)
			setIsShouldUpdate(false)
		}
	}, [isShouldUpdate])

	const handleSave = obj => {
		setNotificationData(obj)
	}

	const handleOpen = () => {
		setShow(true)
	}

	const deleteNotification = () => {
		handleDelete(id)
	}

	const editNotification = data => {
		handleEdit(data)
	}

	return (
		<>
			{showDeleteModal ? (
				<EditDeleteView
					show={showDeleteModal}
					setShow={setShowDeleteModal}
					deleteNotification={deleteNotification}
				/>
			) : (
				''
			)}
			{show ? (
				<NotificationEditView
					workers={workers}
					workCategories={workCategories}
					workerCategories={workerCategories}
					notificationTypes={notificationTypes}
					jwt={jwt}
					editNotification={editNotification}
					show={show}
					data={data}
					handleSave={handleSave}
					setShow={setShow}
				/>
			) : (
				''
			)}
			<Accordion>
				<Accordion.Item eventKey={id} key={id} defaultActiveKey='0'>
					<Accordion.Header>
						<Container fluid='md'>
							<Stack
								style={{ display: 'flex', alignItems: 'center' }}
								className={styles.stack}
								direction='horizontal'
							>
								<Col>
									<p>{data.message}</p>
								</Col>
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Badge
										pill
										bg='primary'
										md='auto'
										style={{ marginLeft: 4, marginRight: 4 }}
									>
										{notificationData.startDate.split('T')[1] + ' ' +notificationData.startDate.split('T')[0].split('-').reverse().join('.').replace('T', ' ')}
									</Badge>
									<Badge
										pill
										bg='primary'
										md='auto'
										style={{ marginLeft: 4, marginRight: 4 }}
									>
										{notificationData.endDate.split('T')[1] + ' ' +notificationData.endDate.split('T')[0].split('-').reverse().join('.').replace('T', ' ')}
									</Badge>
								</Col>
							</Stack>
						</Container>
					</Accordion.Header>
					<Accordion.Body>
						<Container>
							<Row>
								<Col>
									<div className='d-grid gap-2'>
										<Button
											variant='warning'
											onClick={() => {
												handleOpen()
											}}
										>
											Редактировать
										</Button>
									</div>
								</Col>
								<Col>
									<div className='d-grid gap-2'>
										<Button
											variant='danger'
											onClick={() => setShowDeleteModal(true)}
										>
											Удалить
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}

export default Notification
