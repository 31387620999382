import { useCallback, useEffect, useState } from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import Worker from './Worker'

export default function Fine({ workers, isMobile, jwt }) {
	const [searchValue, setSearchValue] = useState('')
	const [searchResult, setSearchResult] = useState([])

	useEffect(() => {
		setSearchResult(filterWorkers(searchValue, workers))
	}, [searchValue])

	const filterWorkers = useCallback((searchValue, workers) => {
		if (!searchValue) {
			return []
		}
		return workers.filter(
			worker =>
				worker.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
				worker.secondName?.toLowerCase().includes(searchValue.toLowerCase()) ||
				worker.thirdName?.toLowerCase().includes(searchValue.toLowerCase())
		)
	}, [])

	return (
		<div>
			<h3>Штрафы</h3>
			<Form>
				<Form.Label>Поисковик</Form.Label>
				<Form.Control
					value={searchValue}
					type='text'
					placeholder='Петя'
					onChange={e => setSearchValue(e.target.value)}
				/>
			</Form>
			<ListGroup
				style={{ maxHeight: 'calc(100vh - 160px)', overflowY: 'auto' }}
			>
				{searchResult.length > 0 ? searchResult.map(worker => (
					<ListGroup.Item key={worker.id} className='mt-2'>
						<Worker workerData={worker} isMobile={isMobile} jwt={jwt} />
					</ListGroup.Item>
				))
					: <ListGroup.Item className='mt-2' variant='warning'>
						Ничего не найдено
					</ListGroup.Item>}
			</ListGroup>
		</div>
	)
}
